import LoadingPage from '@/components/LoadingPage/LoadingPage';
import { LIST_ROUTES } from '@/configs/routes';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

const HomePage = lazy(() => import('@/pages/HomePage/HomePage'));

const Router = (): JSX.Element => {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route
          path={LIST_ROUTES.HOME}
          element={
            <Suspense fallback={<LoadingPage />}>
              <HomePage />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<LoadingPage />}>
              <HomePage />
            </Suspense>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default Router;
