import MessageMap from '../i18n';

const ptBR: MessageMap = {
  SIDE_MENU: { HOME: 'Home' },
  VERSION: {
    VERSION_APP: 'Versão',
    SERVER: 'Servidor',
    RIGHTS_RESERVED: 'Anheuser-Busch InBev S.A. Todos los derechos reservados.',
  },
  HOMEPAGE: {
    //TODO: Translate
    TITLE: 'Customização',
    MOV: {
      TITLE: 'Minimum order value (MOV)',
      DESCRIPTION: 'Manage the minimum value that orders must have.',
    },
    MOW: {
      TITLE: 'Maximum order weight (MOW)',
      DESCRIPTION: "Manage the maximum weight of a cart's total products.",
    },
    DELIVERY_FEE: {
      TITLE: 'Delivery fee',
      DESCRIPTION: 'Manage the value of the order delivery fee. ',
    },
  },
};

export default ptBR;
