import { ApolloProvider } from '@apollo/client';
import { createFeatureToggleServiceV2 } from 'admin-portal-shared-services';
import Layout from './components/Layout/Layout';
import { FEATURE_TOGGLE_V2_PROJECT_NAME } from './configs/featureToggles';
import { getApolloClient } from './graphql/graphql';
import { IntlProvider } from './i18n';
import { EnvConfig } from './types/common.types';
import { getInitialCountry } from './utils/getInitialCountry/getInitialCountry';

export default function App(props: EnvConfig) {
  createFeatureToggleServiceV2(FEATURE_TOGGLE_V2_PROJECT_NAME, props.OPTIMIZELY_KEY);

  const country = getInitialCountry(props.SUPPORTED_COUNTRIES);

  const client = getApolloClient(country);

  return (
    <ApolloProvider client={client}>
      <IntlProvider>
        <Layout />
      </IntlProvider>
    </ApolloProvider>
  );
}
