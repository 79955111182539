import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  SIDE_MENU: { HOME: 'Home' },
  VERSION: {
    VERSION_APP: 'Version',
    SERVER: 'Server',
    RIGHTS_RESERVED: 'Anheuser-Busch Inbev S.A. All rights reserved.',
  },
  HOMEPAGE: {
    TITLE: 'Customization',
    MOV: {
      TITLE: 'Minimum order value (MOV)',
      DESCRIPTION: 'Manage the minimum value that orders must have.',
    },
    MOW: {
      TITLE: 'Maximum order weight (MOW)',
      DESCRIPTION: "Manage the maximum weight of a cart's total products.",
    },
    DELIVERY_FEE: {
      TITLE: 'Delivery fee',
      DESCRIPTION: 'Manage the value of the order delivery fee. ',
    },
  },
};

export default enUS;
